import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import FileLoader from '../file-upload/FileLoader';

const rentalOptions = ['apartment', 'condo', 'house'];

const RentalForm = ({ onSubmit }) => {
  const { register, handleSubmit, errors, getValues, setValue } = useForm();

  useEffect(() => {
    register({ name: 'image' });
  }, [register]);

  const [imageLoaded, setImageLoaded] = useState(true);
  // const [count, setCount] = useState(0);

  // console.log(errors);
  if (errors && errors.length > 0) {
    console.log(errors);
    errors.map((error) => console.log(error.title));
  }

  const errorCheck = (errorType) => {
    if (errors && errors.length > 0) {
      console.log(errors);
      errors.map((error) => {
        if (error.title === errorType) {
          console.log(error.title + error.detail);
          return true;
        }
      });
    }
  };

  console.log(errors);
  // const errorCheck = (errorTitle) => {
  //   if (errors && errors.length > 0) {
  //     console.log(errors);
  //     errors.map((error) => {
  //       // console.log(error.title);
  //       // element.id = error.title;
  //       // element.text = error.detail;
  //       // errorArray.push({ element: element });
  //       if (error.title === errorTitle) {
  //         console.log(error.detail);
  //         return true;
  //       }
  //       return false;
  //     });
  //   }
  // };

  // console.log(errorArray);

  const checkForErrors = (onSubmit) => {
    console.log(errors);
    handleSubmit(onSubmit);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="form-group">
        <label htmlFor="title">Title</label>
        <input
          ref={register({ required: true })}
          name="title"
          type="text"
          className="form-control"
          id="title"
        />

        {/* {errorCheck('title') && <p>titlePath `title` is required</p>} */}
        {errors.title && (
          <div className="alert alert-danger">
            {errors.title.type === 'required' && <span>Title is required</span>}
            {errors.title.type === 'pattern' && (
              <span>Not valid title format</span>
            )}
          </div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="city">City</label>
        <input
          ref={register({ required: true })}
          name="city"
          type="text"
          className="form-control"
          id="city"
        />
        {errors.city && (
          <div className="alert alert-danger">
            {errors.city.type === 'required' && <span>City is required</span>}
            {errors.city.type === 'pattern' && (
              <span>Not valid city format</span>
            )}
          </div>
        )}
      </div>
      {/* {
        console.log(errorCheck('city'))

        // ? null : (
        //   <div className="alert alert-danger">
        //     <p>City is required</p>
        //   </div>
        // )
      } */}

      <div className="form-group">
        <label htmlFor="street">Street</label>
        <input
          ref={register({ required: true })}
          name="street"
          type="text"
          className="form-control"
          id="street"
        />
        {errors.street && (
          <div className="alert alert-danger">
            {errors.street.type === 'required' && (
              <span>Street adress is required</span>
            )}
            {errors.street.type === 'pattern' && (
              <span>Not valid adress format</span>
            )}
          </div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="category">Category</label>

        <select
          ref={register({ required: true })}
          name="category"
          className="form-control"
          id="category"
        >
          <option value="">- Choose a category -</option>
          {rentalOptions.map((option) => (
            <option key={option}>{option}</option>
          ))}
        </select>
        {(errors.category || errors.category === '') && (
          <div className="alert alert-danger">
            {errors.category.type === 'required' && (
              <span>Category is required</span>
            )}
            {errors.category.type === 'pattern' && (
              <span>Not valid category format</span>
            )}
          </div>
        )}
      </div>

      {/* IMAGE CHECK */}
      <div className="form-group">
        <label htmlFor="image">Image</label>
        <FileLoader
          onFileUpload={(image) => setValue('image', image._id)}
          onClick={() => setImageLoaded(true)}
        />
      </div>

      {!imageLoaded && (
        <div className="alert alert-danger">
          <span>Image2 is required</span>
        </div>
      )}

      <div className="form-group">
        <label htmlFor="bedrooms">Rooms</label>
        <input
          ref={register({ required: true })}
          name="numOfRooms"
          type="number"
          className="form-control"
          id="numOfRooms"
        />
        {errors.numOfRooms && (
          <div className="alert alert-danger">
            {errors.numOfRooms.type === 'required' && (
              <span>Number of Rooms is required</span>
            )}
            {errors.numOfRooms.type === 'pattern' && (
              <span>Not valid Number of rooms format</span>
            )}
          </div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="description">Description</label>
        <textarea
          ref={register({ required: true })}
          name="description"
          rows="5"
          type="text"
          className="form-control"
          id="description"
        ></textarea>
        {errors.description && (
          <div className="alert alert-danger">
            {errors.description.type === 'required' && (
              <span>Description is required</span>
            )}
            {errors.description.type === 'pattern' && (
              <span>Not valid description format</span>
            )}
          </div>
        )}
      </div>

      <div className="form-group">
        <label htmlFor="dailyRate">Daily Price</label>
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="input-group-text">$</div>
          </div>
          <input
            ref={register({ required: true })}
            name="dailyPrice"
            type="number"
            className="form-control"
            id="dailyPrice"
          />
          {errors.dailyPrice && (
            <div className="alert alert-danger">
              {errors.dailyPrice.type === 'required' && (
                <span>Daily Price is required</span>
              )}
              {errors.dailyPrice.type === 'pattern' && (
                <span>Not valid Daily Price format</span>
              )}
            </div>
          )}
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="shared">Shared</label>
        <input
          // ref={register({ required: true })}
          name="shared"
          type="checkbox"
          className="form-control"
          id="shared"
        />
        {/* {errors.shared && (
          <div className="alert alert-danger">
            {errors.shared.type === 'required' && (
              <span>Shared/Not-Shared is required</span>
            )}
            {errors.shared.type === 'pattern' && (
              <span>Not valid Shared/Not-Shared format</span>
            )}
          </div>
        )} */}
      </div>
      <button
        disabled={!imageLoaded}
        type="submit"
        className="btn btn-bwm-main"
      >
        Create
      </button>
    </form>
  );
};

export default RentalForm;
